import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import * as sH from 'sanitize-html'
import flatListToHierarchical from '../../utils/flatListToHierarchical'

const FooterMenu = ({ menu }) => {
  const menuItems = flatListToHierarchical(menu)

  const MenuWrapper = styled.div`
    ${tw`mt-4 space-y-4`}
  `
  const MenuItemWrapper = styled.div`
    ${tw``}
  `
  const MenuItem = styled(props => <Link {...props} />)`
    ${tw`text-base md:text-lg text-white hover:text-gray-300`}
  `

  return (
    <MenuWrapper>
      {menuItems?.map(({ key, url, title } = {}) => (
        <MenuItemWrapper key={key}>
          <MenuItem to={url} dangerouslySetInnerHTML={{ __html: sH(title) }} />
        </MenuItemWrapper>
      ))}
    </MenuWrapper>
  )
}

FooterMenu.propTypes = {
  menu: PropTypes.array.isRequired,
}

export default FooterMenu
