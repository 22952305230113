/* eslint-disable react/no-danger */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import sanitizeHtml from 'sanitize-html'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon'
import { Parallax } from 'react-scroll-parallax'
import Obfuscate from 'react-obfuscate'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import FooterMenu from './FooterMenu'
import WhiteLogo from '../../assets/images/red-white.svg'
import RinvFooter from '../../assets/images/r-inv-footer.svg'

const navigation = {
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/rico-estate-development-ab',
      icon: () => <FontAwesomeSvgIcon className="text-white" icon={faLinkedin} size="2x" />,
    },
  ],
}

const Wrapper = styled.footer`
  ${tw`bg-primary`}
`
const Container = styled.div`
  ${tw`bg-primary max-w-screen-2xl mx-auto py-12 lg:py-16 px-8 text-base md:text-lg relative overflow-y-hidden overflow-x-visible`}
`
const Content = styled.div`
  ${tw`lg:grid lg:grid-cols-3 lg:gap-8`}
`
const InformationWrapper = styled.div`
  ${tw`space-y-8 lg:col-span-1`}
`
const SocialMenu = styled.div`
  ${tw`flex space-x-6 text-white`}
`
const CopyrightWrapper = styled.div`
  ${tw`mt-12`}
`
const MenuWrapper = styled.div`
  ${tw`mt-12 grid lg:grid-cols-2 gap-8 md:mt-0 lg:col-span-2`}
`
const MenuGrid = styled.div`
  ${tw`grid grid-cols-2 gap-8`}
`
const MenuContainer = styled.div``

const CopyrightNotice = styled.p`
  ${tw`text-base text-white lg:text-center`}
`
const Address = styled.address`
  ${tw`not-italic text-white`}
`
const Email = styled(props => <Obfuscate {...props} />)`
  ${tw`text-white`}
`
const Watermarks = styled.div`
  ${tw`w-72 right-0 md:right-0 md:transform absolute lg:w-[350px] bottom-[-350px] lg:bottom-[-375px] xl:bottom-[-375px] overflow-x-visible`};
`

const Footer = () => {
  const {
    en: { nodes: en } = {},
    sv: { nodes: sv } = {},
    wp: { globalSettings: { globalSettings: { address, email } = {} } = {} } = {},
  } = useStaticQuery(graphql`
    query FOOTER_MENU_ITEMS {
      en: allWpMenuItem(filter: { locations: { eq: GATSBY_FOOTER_MENU___EN } }, sort: { fields: order }) {
        nodes {
          ...MenuItem
        }
      }
      sv: allWpMenuItem(filter: { locations: { eq: GATSBY_FOOTER_MENU } }, sort: { fields: order }) {
        nodes {
          ...MenuItem
        }
      }
      wp {
        globalSettings {
          globalSettings {
            address
            email
          }
        }
      }
    }
  `)

  const menu = useI18next().language === 'en' ? en : sv

  const firstMenu = menu.slice(0, Math.ceil(menu.length / 2))
  const secondMenu = menu.slice(-Math.floor(menu.length / 2))

  return (
    <Wrapper>
      <Container>
        <Parallax speed={-10} className="overflow-x-visible absolute-right-0">
          <Watermarks className="-z-0">
            <RinvFooter className="w-full h-full" />
          </Watermarks>
        </Parallax>
        <Link to="/" className="pt-0 lg:pt-7">
          <WhiteLogo className="h-8 md:h-12 w-auto" />
        </Link>
        <Content className="pt-7">
          <InformationWrapper>
            <Address dangerouslySetInnerHTML={{ __html: sanitizeHtml(address) }} />
            <Email email={email} />
            <SocialMenu>
              {navigation.social.map(item => (
                <a key={item.name} href={item.href} target="_blank" className="hover:text-gray-300" rel="noreferrer">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </SocialMenu>
          </InformationWrapper>
          <MenuWrapper>
            <MenuGrid>
              <MenuContainer>
                <FooterMenu menu={firstMenu} />
              </MenuContainer>
              <MenuContainer>
                <FooterMenu menu={secondMenu} />
              </MenuContainer>
            </MenuGrid>
          </MenuWrapper>
        </Content>
        <CopyrightWrapper>
          <CopyrightNotice>© {new Date().getFullYear()} All rights reserved.</CopyrightNotice>
        </CopyrightWrapper>
      </Container>
    </Wrapper>
  )
}

export default Footer
